<template lang="pug">
  .wrapper.i-wrap.cms-article-wrap
    .section-left

    .section-mid
      div
        input(v-model="fetchTmdbId")
        button(@click="fetchByTmdbId('movie')") fetch Movie by tmdbID
        button(@click="fetchByTmdbId('tvSeries')") fetch tvSeries by tmdbID
        router-link(to="/admin/movieNew") Admin New Movie      
      div
        router-link(to="/admin/movieEdit") Admin Edit Movie      
      //- div
        router-link(to="/admin/starNew") Admin New Star      
      div
        router-link(to="/admin/starEdit") Admin Edit Star   
      //- div
        p Done: {{moviesDone}}
        p Not Done: {{moviesNotDone}}
      div
        //- button(@click="gogogirl") go
      //- h1 Adult Movies
      //- div(v-for="(movie,index) in adultMovies" )
        router-link(:to="`/movie/${movie._id}`" target="_blank")
          img(v-bind:src="movie.thumbnail")
        p {{movie._id}}
        p {{movie.tmdbId}}
        p {{movie.nameCH}}
        p {{movie.nameEN}}
        p {{movie.nameOrig}}
        p {{movie.adult}}
        button(@click="porn(index, movie._id, true)" style="background-color:red; color:white; margin:20px 20px 20px 0; ") PORN
        button(@click="porn(index, movie._id, false)" style="background-color: green; color: white;") NOT PORN
      //- h1 Racy Movies
      //- div(v-for="movie in racyMovies")
        router-link(:to="`/movie/${movie._id}`" target="_blank")
          img(v-bind:src="movie.thumbnail")
          p {{movie._id}}
      
    .section-right
      

</template>

<script>
// @ is an alias to /src
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-vue';

import Datepicker from 'vuejs-datepicker';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';


export default {
  name: 'CmsAdminHome',
  props:{
    id:String,
  },
  components: {
    VueEditor,
    Datepicker,
    LeftBar,
    RightBar,
    VueCropper,
  },
  data(){
    return{
      adultMovies:[],
      moviesDone:null,
      moviesNotDone:null,
      racyMovies:[],
      fetchTmdbId:null,
    }
  },
  metaInfo: {
    title: 'Admin- 臭豆腐電影: 電影∣影集∣評分∣評論',
    // titleTemplate: '%s - 臭豆腐電影: 電影∣影集∣評分∣評論'
  },
  methods:{
    async init(){
      var abc = await this.axios.get(`/cms`)
      this.adultMovies = abc.data.adultMovies
      this.moviesDone = abc.data.moviesDone
      this.moviesNotDone = abc.data.moviesNotDone
      this.racyMovies = abc.data.racyMovies
    },
    async porn(index, id, porn){
      try{
        // console.log(index,id,porn)
        this.adultMovies.splice(index,1)
        var response = await this.axios.post(`/cms`, { id:id, porn:porn })
        if (response.data.err == false ) this.flash('ok', 'success')
      }catch(err){
        this.flash(err,'error')
      }

    },
    async fetchByTmdbId(type){
      var {data} = await this.axios.post(`/cms/tmdbUpdate`,{type:type,tmdbId:`${this.fetchTmdbId}`})


    },
    async gogogirl(){
      var adult = await this.axios.post(`/cms`)
      await this.init()
    }
  },
  async created(){
    this.init()
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss">

</style>
